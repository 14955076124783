import moment from 'moment';

import { getGlobalT } from '../../../i18n/setup-translations';
import { ISubscriptionDetails } from '../../user-subscriptions/interfaces';
import { SubscriptionOptionType } from '../../user-subscriptions/interfaces-api';
import { IPaymentHistoryItem } from '../interfaces';
import { IRawSubscriptionPaymentHistoryItem, IRawOptionsPaymentHistoryItem } from '../interfaces-api';
import { getInvoiceUrl } from '../services/account.service';
import { getSubscriptionName } from '../../subscription-offers/mappers/map-subscription-offers-variants'
import { getOptionName } from '../../user-subscriptions/mappers/map-subscription-to-options'
export const mapToPaymentHistoryItem = (
    /**
     * rawPaymentItem contains subscription and options payments
     */
    rawPaymentItem: IRawSubscriptionPaymentHistoryItem | IRawOptionsPaymentHistoryItem,
    userSubscriptions: ISubscriptionDetails[],
): IPaymentHistoryItem => {
    const t = getGlobalT();

    const relatedSubscription = userSubscriptions.find(
        (sub) => sub.id === rawPaymentItem.subscription_id,
    ) as ISubscriptionDetails;

    const getInvoice = () => {
        if (rawPaymentItem.status === 'success') {
            return getInvoiceUrl(rawPaymentItem.payment_id);
        }
    };

    const data: IPaymentHistoryItem = {
        date: moment(rawPaymentItem.date).format('D MMM YYYY'),
        id: rawPaymentItem.id,
        invoiceUrl: getInvoice(),
        paymentId: rawPaymentItem.payment_id,
        status: rawPaymentItem.status,
        paymentName: "",
    };

    // Is IRawOptionsPaymentHistoryItem
    if ("options_fields" in rawPaymentItem) {
        rawPaymentItem.options_fields.forEach((option) => {
            if (data.paymentName === "") {
                data.paymentName = "" + getOptionName(option.code)
            } else {
                data.paymentName = data.paymentName + " + " + getOptionName(option.code)
            }
        })
    // Is IRawSubscriptionPaymentHistoryItem
    } else {
        data.paymentName = getSubscriptionName(rawPaymentItem.offer.code)
    }

    return data;
};
