import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { config } from '../../../config';
import { ActiveIndicator } from '../../common/active-indicator/active-indicator.component';
import { Button } from '../../common/button/button.component';
import { Link } from '../../common/link/link.component';
import { Typography } from '../../common/typography/typography.component';
import { ReactComponent as PowerIcon } from '../../icons/power.svg';
import { ReactComponent as EditIcon } from '../../icons/subscrption.svg';

import styles from './subscription-details.module.scss';

export interface ISubscriptionDetailsProps {
    paymentError: boolean | undefined;
    data: {
        expireIn: number | null;
        expiryDate: string | null;
        isActive: boolean;
        isCancelled: boolean;
        isCancelScheduled: boolean;
        isNew: boolean;
        isExpired: boolean;
        isRefunded: boolean;
        payment_mean: string | null | undefined;
        planName: string;
        price: string;
        pricePeriod: string;
        renewalDate: string | null;
        renewalPrice: string | null;
        paymentPending: boolean | null | undefined;
        offerType: string | null;
    } | null;
    onManageSubscriptionClicked: () => any;
    onPayAndActivateClicked: () => any;
    onActivateClicked: () => any;
    onUncancelClicked: () => any;
    onChangePaymentMeanClicked: () => any;
}

const supportLink = config.SUPPORT_LINK;

export const SubscriptionDetails = ({
    paymentError,
    data,
    onManageSubscriptionClicked,
    onActivateClicked,
    onUncancelClicked,
    onPayAndActivateClicked,
}: ISubscriptionDetailsProps) => {
    const { t } = useTranslation('subscriptions');

    const renderActiveCase = () => {
        const {
            planName,
            pricePeriod,
            price,
            renewalDate,
            renewalPrice,
        } = data!;

        return (
            <React.Fragment>
                <div className={styles['top-row']}>
                    <div className={styles['plan-name']}>{planName}</div>
                    <ActiveIndicator
                        className={styles['active-indicator']}
                        active={true}
                    />
                    <div className={styles['price-or-expire']}>
                        {price} / {pricePeriod}
                    </div>
                </div>
                <p className={styles['info-text']}>
                    {t('DETAILS.ACTIVE_CASE.RENEWAL_INFO', {
                        date: renewalDate,
                        price: renewalPrice || price,
                    })}
                </p>
                <div className={styles['buttons-container']}>
                    <Button
                        iconLeft={<EditIcon />}
                        small
                        className={styles['manage-button']}
                        secondary
                        onClick={onManageSubscriptionClicked}
                    >
                        {t('DETAILS.ACTIVE_CASE.MANAGE_BUTTON')}
                    </Button>
                </div>
            </React.Fragment>
        );
    };

    /**
     * New case is when there is subscription in database, but not paid.
     * So the only reason for it is when payment failed
     */
    const renderNewCase = () => (
        <React.Fragment>
            <Typography size12><>{
                data !== null && data.paymentPending == true ?
                t('DETAILS.NEW_CASE.WAITING_TEXT')
                : t('DETAILS.NEW_CASE.INFO_TEXT')
            }</></Typography>
            <Typography size12 withMarginBottom>
                <Trans i18nKey="subscriptions:DETAILS.SUPPORT_TEXT">
                    Having problems with activating your tracker? Contact our
                    <Link href={supportLink} target="_blank">
                        Help Center
                    </Link>
                </Trans>
            </Typography>
            <Button
                small
                iconLeft={<PowerIcon />}
                onClick={onPayAndActivateClicked}
                disabled={data !== null && data.paymentPending == true}
            >
                {t('DETAILS.NEW_CASE.PAY_BUTTON')}
            </Button>
        </React.Fragment>
    );

    /**
     * If there is no subscription - user haven't submitted form,
     * didn't go to payment - subscription is null
     */
    const renderNoSubCase = () => (
        <React.Fragment>
            <Typography size12 withMarginBottom>
                <> {t('DETAILS.NO_SUB.INFO_TEXT')}</>
            </Typography>
            <Typography size12 withMarginBottom>
                <Trans i18nKey="subscriptions:DETAILS.SUPPORT_TEXT">
                    Having problems with activating your tracker? Contact our
                    <Link href={supportLink} target="_blank">
                        Help Center
                    </Link>
                </Trans>
            </Typography>
            <Button small iconLeft={<PowerIcon />} onClick={onActivateClicked}>
                {t('DETAILS.ACTIVATE_BUTTON.LABEL')}
            </Button>
        </React.Fragment>
    );

    const renderCanceledCase = () => {
        const { planName, expireIn, expiryDate, isExpired } = data!;

        let expiryInText: string;
        let mainText: string;
        let active: boolean;

        if (expireIn !== null && expireIn > 0) {
            mainText = t('DETAILS.CANCELED.WILL_EXPIRE_MAIN', {
                date: expiryDate,
            });
            expiryInText = t('DETAILS.CANCELED.WILL_EXPIRE_DAYS', {
                count: expireIn,
            });
            active = true;
        } else {
            mainText = t('DETAILS.CANCELED.EXPIRED_MAIN');
            expiryInText = t('DETAILS.CANCELED.EXPIRED_SEC');
            active = false;
        }

        const maybeRenderButton = () => {
            if (paymentError === true) {
                /*
                    Case When subscription has been canceled du to max payment errors reached
                */
                return (
                    <Button
                        small
                        iconLeft={<PowerIcon />}
                        onClick={onPayAndActivateClicked}
                    >
                        {t('DETAILS.NEW_CASE.PAY_BUTTON')}
                    </Button>
                );
            } else if (isExpired) {
                /*
                    Case When subscription is canceled and expired.

                    /!\
                        Here we *need* to redirect to manage subscription page
                        because the canChangePlan is set there, which restrict the Credit Card choice
                        on payments-steps component.
                        If you wish to directly redirect to variant-choice step, please also set the canChangePlan variable.
                    /!\
                */
                return (
                    <div className={styles['buttons-container']}>
                        <Button
                            small
                            iconLeft={<EditIcon />}
                            onClick={onManageSubscriptionClicked}
                        >
                            {t('DETAILS.ACTIVE_CASE.MANAGE_BUTTON')}
                        </Button>
                    </div>
                );
            } else if (expireIn !== null && expireIn >= 0) {
                /*
                    Case When subscription is canceled but not yet expired
                    (still ongoing subscription)
                */
                return (
                    <Button
                        small
                        iconLeft={<PowerIcon />}
                        onClick={onUncancelClicked}
                    >
                        <>{t('DETAILS.CANCELED.UNCANCEL_BUTTON_LABEL')}</>
                    </Button>
                );
            }
        };

        return (
            <React.Fragment>
                <div className={styles['top-row']}>
                    <div className={styles['plan-name']}>{planName}</div>
                    <ActiveIndicator
                        className={styles['active-indicator']}
                        active={active}
                    />
                    <div className={styles['price-or-expire']}>
                        {expiryInText}
                    </div>
                </div>
                <Typography size12>{mainText}</Typography>
                {maybeRenderButton()}
            </React.Fragment>
        );
    };

    const renderTrialCase = () => {
        const {
            planName,
            expiryDate,
            isExpired
        } = data!;
        return isExpired ? (
            <React.Fragment>
                <div className={styles['top-row']}>
                    <div className={styles['plan-name']}>{planName}</div>
                    <ActiveIndicator
                     className={styles['active-indicator']}
                     active={false}
                     customText={t('DETAILS.CANCELED.EXPIRED_SEC')}
                    />
                </div>
                <p className={styles['info-text']}>
                    {t('subscriptionAlert:TRIAL_CASE.TRIAL_IS_OVER') +'.'}
                    <br/>
                    {t('subscriptionAlert:TRIAL_CASE.TRIAL_IS_OVER_ADVICE')}
                </p>
                <div className={styles['buttons-container']}>
                    <Button
                        small
                        iconLeft={<PowerIcon />}
                        onClick={onManageSubscriptionClicked}
                    >
                        {t('DETAILS.SUBSCRIBE_BUTTON.LABEL')}
                    </Button>
                </div>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div className={styles['top-row']}>
                    <div className={styles['plan-name']}>{planName}</div>
                    <ActiveIndicator
                     className={styles['active-indicator']}
                     active={true}
                    />
                </div>
                <p className={styles['info-text']}>
                    {t('DETAILS.TRIAL_PERIOD_CASE.END_INFO', {
                        date: expiryDate,
                    })}
                </p>
                <div className={styles['buttons-container']}>
                    <Button
                        iconLeft={<EditIcon />}
                        small
                        className={styles['manage-button']}
                        secondary
                        onClick={onManageSubscriptionClicked}
                    >
                        {t('DETAILS.SUBSCRIBE_BUTTON.LABEL')}
                    </Button>
                </div>
            </React.Fragment>
        );
    };

    const renderContent = () => {
        if (!data) {
            return renderNoSubCase();
        } else if (data.offerType === 'promotional') {
            return renderTrialCase();
        } else if (data.isActive || data.isCancelScheduled) {
            return renderActiveCase();
        } else if (data.isCancelled) {
            return renderCanceledCase();
        } else if (data.isNew || data.isRefunded) {
            return renderNewCase();
        }
    }
    
    return <div className={styles.container}>{renderContent()}</div>;
};
