import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useLocation } from 'react-router';

import { ConnectedGeofencesPage } from '../../geofences/geofences-page/geofences-page.component';
import { ConnectedHistoryRoute } from '../../history/history-route/history-route.component';
import { CardSection } from '../../layout/card-section/card-section.component';
import { TrackerSettingsRoute } from '../../tracker-settings/tracker-settings-route/tracker-settings-route.component';
import { ConnectedSubscriptionSettings } from '../../user-subscriptions/subscription-settings/subscription-settings.component';
import { ConnectedWifiZonePage } from '../../wifi-zones/wifi-zones-page/wifi-zones-page.component';
import styles from './inner-routes.module.scss';

interface IMapPageInnerRoutesProps {
    activeTrackerName: string;
    isMobileRedirect: boolean;
    onInnerRouteClose(): unknown;
}


/**
 * Routes in sidebar
 */
export const MapPageInnerRoutes = ({
    onInnerRouteClose,
    activeTrackerName,
    isMobileRedirect,
}: IMapPageInnerRoutesProps) => {
    const { t } = useTranslation('trackers');
    const route = useLocation()
    const { id } = useParams()
    const resolveRouting = () => {
        if (route.pathname === `/trackers/${id}/history`) {
            return (
                <CardSection
                    stretch
                    onClose={onInnerRouteClose}
                    breadcrumbs={[
                        activeTrackerName,
                        t('LINKS.HISTORY'),
                    ]}
                    className={cx([
                        styles['history-card'],
                        styles.card,
                    ])}
                    isMobileRedirect={isMobileRedirect}
                >
                    <ConnectedHistoryRoute />
                </CardSection>

            )
        }
        if (route.pathname === `/trackers/${id}/subscription`) {
            return (
                <CardSection
                    onClose={onInnerRouteClose}
                    breadcrumbs={[
                        activeTrackerName,
                        t('LINKS.SUBSCRIPTION'),
                    ]}
                    className={cx([
                        styles['subscription-card'],
                        styles.card,
                    ])}
                    isMobileRedirect={isMobileRedirect}
                >
                    <ConnectedSubscriptionSettings />
                </CardSection>

            )
        }
        if (route.pathname === `/trackers/${id}/geofences`) {
            return (

                <CardSection
                    onClose={onInnerRouteClose}
                    breadcrumbs={[
                        activeTrackerName,
                        t('LINKS.GEOFENCES'),
                    ]}
                    className={cx([
                        styles['geofences-card'],
                        styles.card,
                    ])}
                    isMobileRedirect={isMobileRedirect}
                >
                    <ConnectedGeofencesPage />
                </CardSection>
            )
        }
        if (route.pathname === `/trackers/${id}/wifi-zones`) {
            return (
                <CardSection
                    onClose={onInnerRouteClose}
                    breadcrumbs={[activeTrackerName, 'Zones Wifi']}
                    className={cx([
                        styles['wifi-zone-card'],
                        styles.card,
                    ])}
                    isMobileRedirect={isMobileRedirect}
                >
                    <ConnectedWifiZonePage />
                </CardSection>
            )
        }
        if (route.pathname === `/trackers/${id}/settings`) {
            return (
                <CardSection
                    onClose={onInnerRouteClose}
                    breadcrumbs={[
                        activeTrackerName,
                        (isMobileRedirect ?
                            t('activityOnboarding:COMMON.TRACKER_PROFIL')
                            : t('LINKS.SETTINGS')),
                    ]}
                    className={cx([
                        styles['settings-card'],
                        styles.card,
                    ])}
                    isMobileRedirect={isMobileRedirect}
                >
                    <TrackerSettingsRoute />
                </CardSection>
            )
        }
    }
    return (
        <React.Fragment>
            {resolveRouting()}
        </React.Fragment>)
};
