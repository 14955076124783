import React, { ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { pure } from 'recompose';
import { Spinner } from './common/loading-spinner/loadingSpinner.component';
import { Dispatch, IRootState } from './store/store';
import { IUser } from './user/interfaces-api';

export interface IActions {
    recoverSession: () => any;
    prefetchData: (site: string) => Promise<any>;
    setMobileRedirect: (isMobileRedirect: boolean) => any;
}

export interface IState {
    logged: boolean;
    loggedByForm: boolean;
    loggedByToken: boolean;
    user: IUser;
}

export interface IOuterProps {
    children: ReactNode;
}

export interface IAppBootstrapProps extends IActions, IOuterProps, IState { }

/**
 * Call async effects to preload app's data here
 */
export const AppBootstrapRaw = pure(
    ({
        children,
        logged,
        prefetchData,
        recoverSession,
        setMobileRedirect,
        user,
        loggedByForm,
        loggedByToken,
    }: IAppBootstrapProps) => {
        const [loading, setLoading] = useState(true);
        const [recoveredFromSession, setRecoveredFromSession] = useState(false);
        useEffect(() => {
            if (window.location.href.includes('source=app')) {
                sessionStorage.setItem("isMobileRedirect", "true");
                setMobileRedirect(true);
            } else if (window.location.href.includes('source=')) {
                sessionStorage.removeItem("isMobileRedirect");
                setMobileRedirect(false);
            } else if (sessionStorage.getItem("isMobileRedirect") === "true") {
                setMobileRedirect(true);
            }
        }, [])
        const doRecoverFromSession = () => {
            return recoverSession();
        };
        /**
         * Hooks when user refresh page after being already logged
         */
        useEffect(() => {
            if (logged === null || logged === false) {
                doRecoverFromSession()
                    .then((currentUser: IUser) => {
                        setRecoveredFromSession(true);
                        return prefetchData(currentUser.site);
                    })
                    .then(() => {
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                        // Can not recover session from session storage, go on...
                    });
            }
        }, [logged]);

        /**
         * Hooks when user gets logged manually - it fetches data
         */
        useEffect(() => {
            if (logged && !recoveredFromSession && (loggedByForm || loggedByToken)) {
                setLoading(true);
                prefetchData(user.site).then(() => {
                    setLoading(false);
                });
            }
        }, [logged]);

        if (loading) {
            return <Spinner loading={loading} />;
        }
        return <React.Fragment>{children}</React.Fragment>;
    },
);

const mapState = (state: IRootState) => ({
    logged: state.user.userData && state.login.logged,
    user: state.user.userData,
    loggedByForm: state.login.loggedByForm,
    loggedByToken: state.login.loggedByToken
});

const mapDispatch = (dispatch: Dispatch): IActions => ({
    recoverSession: () => dispatch.login.loginFromSession(),
    setMobileRedirect: (isMobileRedirect: boolean) => dispatch.layout.setMobileRedirect(isMobileRedirect),
    prefetchData: (site: string) => {
        return Promise.all([
            /**
             * Fetch trackers.
             * Having deepFetch set to true allows to also fetch :
             *    - subscription of each tracker
             *    - geofences of each tracker
             */
            dispatch.userTrackers.fetchTrackers({ deepFetch: true }),
            dispatch.subscriptionOffers.fetchSubscriptionVariants(
                `site=${site}`,
            ),
        ]).then(() => {
            return Promise.all([
                dispatch.userTrackers.continuouslyFetchTrackersPositions(),
                dispatch.trackerLiveMode.checkLiveModeStatus(),
                // Set the Apply_free state var of the retailer Store
                // by looking at every retailers of the customer's trackers.
                dispatch.retailer.fetchApplyFree(),
            ]);
        });
    },
});

const ConnectedAppBootstrap = compose<IAppBootstrapProps, IOuterProps>(
    connect(mapState, mapDispatch),
)(AppBootstrapRaw);

export { ConnectedAppBootstrap as AppBootstrap };
