import { IRawUserData } from 'app/user/interfaces';
import { IAccountOptionOffers, IAccountOptions } from 'app/user/interfaces-api';
import {
    makeGetAccountOptions,
    makeGetAccountOptionsOffers,
} from 'app/user/selectors/account-option-selector';
import { putAccountOption } from 'app/user/user.service';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import cx from 'classnames';

import { compose } from 'recompose';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as AmericanExpress } from '../../../../images/american_express.svg';
import { ReactComponent as Bancontact } from '../../../../images/bancontact.svg';
import { ReactComponent as Cb } from '../../../../images/cb.svg';
import { ReactComponent as Mastercard } from '../../../../images/Mastercard.svg';
import { ReactComponent as Visa } from '../../../../images/Visa.svg';
import {
    IWithSelectedTracker,
    withSelectedTracker,
} from '../../../../utils/with-selected-tracker';
import { BackLink } from '../../../common/back-link/back-link.component';
import { BackButton } from '../../../common/button/back-button.component';
import { Button } from '../../../common/button/button.component';
import { NextButton } from '../../../common/button/next-button.component';
import { BiogaranCGU } from '../../../common/cgu/cgu-biogaran.component';
import { CheckSection } from '../../../common/check-section/check-section.component';
import { Headline } from '../../../common/headline/headline.component';
import { Typography } from '../../../common/typography/typography.component';
import { ReactComponent as LoadingGif } from '../../../icons/loading.svg';
import { Grid } from '../../../layout/grid/grid.component';
import { PageContainer } from '../../../layout/page-container/page-container.component';
import { SimpleHeaderWithLogout } from '../../../layout/simple-header/simple-header.component';
import { INotification } from '../../../notifications/interfaces';
import {
    resolveSubscriptionRoute,
    subscriptionProcessRoute,
    trackersRoute,
} from '../../../routing/routes';
import { Dispatch, IRootState } from '../../../store/store';
import {
    IOptionOffer,
    ISubscriptionOffer,
    SubscriptionVariantCode,
} from '../../../subscription-offers/interfaces';
import {
    makeGetAvailablePaymentTypes,
    makeGetSubscriptionsOffersVariants,
} from '../../../subscription-offers/selectors/subscription-offers-selectors';
import { Theme } from '../../../trackers/interfaces';
import { makeGetTrackerRegistrationDetails } from '../../../trackers/selectors/get-tracker-registration-details';
import {
    IRegistrationDetails,
    ISubscriptionDetails,
    ISubscriptionOptionData,
} from '../../../user-subscriptions/interfaces';
import {
    IRawUserSubscription,
    SubscriptionOptionType,
} from '../../../user-subscriptions/interfaces-api';
import {
    makeGetSubscriptionDetailsFromTracker,
    makeGetSubscriptionOptions,
} from '../../../user-subscriptions/selectors/user-subscriptions.selectors';
import { ConnectedSummaryTable } from '../../components/summary-table/summary-table.component';
import { hasUserChoicesGuard } from '../../guards/has-user-choices-guard';
import {
    IPaymentType,
    IVerifyPromoCodeResponse,
    PaymentCode,
} from '../../interfaces';
import { newTrackerRoutes } from '../../new-tracker-routes';
import { PaymentRedirectionUrls } from '../../payment-redirection-routes';
import { makeGetSubscriptionProcessChoices } from '../../selectors/subscription-process-selectors';
import { redirectToPaymentGate } from '../../services/order-subscription.service';
import { IUserChoices } from '../../subscription-process.store';
import { HipayStep } from '../hipay-step/hipay-step.component';
import { IProcessPayment } from '../hipay-step/interfaces';
import styles from './payments-step.module.scss';

interface IActions {
    setSelectedOptions: (newOptions: SubscriptionOptionType[]) => unknown;
    showNotification: (n: INotification) => unknown;
    fetchSubscriptionDetails: (subscriptionId: number) => Promise<any>;
    prefillSubscriptionChoices: (sub: IRawUserSubscription) => Promise<unknown>;
    resetSelectedPaymentType: (
        availablePayments: IPaymentType[],
        userSelectedPaymentCode: PaymentCode | null,
    ) => Promise<any>;
    processPayNow: (paymentPayload: IProcessPayment) => Promise<any>;
    setIsResolvingSubscription: (value: boolean) => Promise<any>;
    setSubscriptionWhileResolving: (value: boolean) => Promise<any>;
    setPaymentType: (paymentType: PaymentCode) => Promise<any>;
    setSelectedSubscription: (subCode: SubscriptionVariantCode) => Promise<any>;
    setSelectedSubscriptionOffer: (subOfferId: number) => Promise<any>;
    setSelectedTracker: (trackerId: number) => Promise<any>;
    verifyPromoCode: (
        promoCode: string,
        offerId: number,
        trackerId?: number,
    ) => Promise<any>;
    setBasketPromoCode: (promoCodeId: number, discount: number) => any;
}

interface IState {
    selectedAccountOptions: SubscriptionOptionType[];
    accountOptionOffers: IAccountOptionOffers[] | undefined;
    user: IRawUserData | null;
    currenTrackerId: number | null;
    canSubmit: boolean;
    isLoading: boolean;
    offers: ISubscriptionOffer[];
    options: ISubscriptionOptionData[] | null;
    registrationDetails: IRegistrationDetails | null;
    subscriptionDetails: ISubscriptionDetails | null;
    rawSubscriptionDetails: IRawUserSubscription | null;
    rawSubscription: IRawUserSubscription | null;
    choiceTrackerId: number;
    isResolvingSubscription: boolean;
    getSubscriptionWhileResolving: boolean;
    paymentTypes: IPaymentType[];
    selectedOfferCode: SubscriptionVariantCode;
    selectedOfferId: number;
    selectedPaymentType: PaymentCode | null;
    promoCodeIsGiftCard: boolean | null;
    subscriptionResolving: null | ISubscriptionDetails;
}

interface IOuterProps {
    hipayFormID: string;
    subscriptionId: number;
    displayCGU: boolean;
    overideSelected?: boolean;
    theme?: Theme | null;
    onSubmit: () => any;
    createSubscription: () => any;
    setDisplayCGU: (value: boolean) => any;
    onCheckboxChanged: (code: SubscriptionOptionType) => any;
    renderConfirmationModal: () => any;
    userChoices: IUserChoices;
}

export interface IPaymentsStepProps
    extends IOuterProps,
        IActions,
        IWithSelectedTracker,
        IState {}

export const PaymentsStep = ({
    accountOptionOffers,
    setSelectedOptions,
    selectedAccountOptions,
    canSubmit,
    isLoading,
    createSubscription,
    subscriptionId,
    hipayFormID,
    renderConfirmationModal,
    onCheckboxChanged,
    choiceTrackerId,
    displayCGU,
    isResolvingSubscription,
    onSubmit,
    paymentTypes,
    processPayNow,
    showNotification,
    resetSelectedPaymentType,
    prefillSubscriptionChoices,
    promoCodeIsGiftCard,
    fetchSubscriptionDetails,
    selectedOfferId,
    selectedPaymentType,
    subscriptionDetails,
    registrationDetails,
    rawSubscription,
    rawSubscriptionDetails,
    setDisplayCGU,
    userChoices,
    user,
    setIsResolvingSubscription,
    setSubscriptionWhileResolving,
    getSubscriptionWhileResolving,
    setPaymentType,
    selectedOfferCode,
    setSelectedSubscription,
    setSelectedSubscriptionOffer,
    setSelectedTracker,
    subscriptionResolving,
    theme,
    currenTrackerId,
    trackerId,
    verifyPromoCode,
    setBasketPromoCode,
    offers,
    ...props
}: IPaymentsStepProps) => {
    /**
     * N.B. 'trackerId' is the id of tracker during the resolve subscription step (fetch with withSelectedTracker)
     * whereas during the regular subscription tunnel we use 'choiceTrackerId' (from subscription-process.store.ts)
     */

    const { t } = useTranslation([
        'subscriptionPayment',
        'subscriptionProcess',
        'HipayPayments',
        'subscriptionSelection',
    ]);
    const subscriptionT = useTranslation('subscriptionOptionsSelection').t;

    const errorT = useTranslation('errors').t;
    const subT = useTranslation('subscription').t;
    const hipayPaymentType = paymentTypes.find(
        (paymentType) => paymentType.code === PaymentCode.HIPAY,
    );
    const [disableValidateButton, setDisableValidateButton] = useState(true);
    const [promoCode, setPromoCode] = useState('');
    const [promoCodeError, setPromoCodeError] = useState(false);

    resetSelectedPaymentType(paymentTypes, selectedPaymentType);
    const location = useLocation();
    const history = useNavigate();
    const { id } = useParams();
    const formatedId = parseInt(id!, 10);

    useEffect(() => {
        if (rawSubscriptionDetails && getSubscriptionWhileResolving) {
            setSubscriptionWhileResolving(false);
            prefillSubscriptionChoices(rawSubscriptionDetails);
        }
    }, [
        isResolvingSubscription,
        rawSubscriptionDetails,
        getSubscriptionWhileResolving,
    ]);
    // Defines if customers is resolving payment errors for their subscription.
    useEffect(() => {
        if (
            location.pathname.includes(
                resolveSubscriptionRoute.split('/').pop()!,
            )
        ) {
            setSubscriptionWhileResolving(true);
            setIsResolvingSubscription(true);
            // Redirect to payment step to inject subscription-process-page logic to this component
            if (!formatedId) {
                return;
            }
            return history(subscriptionProcessRoute + '/payment/' + formatedId);
        }
    });

    useEffect(() => {
        // If selectedOfferId === 0 (default state value)
        if (!selectedOfferId) {
            const offerByCode = offers.find(
                (offer) => offer.code === selectedOfferCode,
            );
            setSelectedSubscriptionOffer(offerByCode!.id);
        }
    }, []);

    const onSubmitResolving = () => {
        return history(subscriptionProcessRoute + `/credit-card/${formatedId}`);
    };

    const onGoBack = () => {
        return history(`/add/options/${formatedId}`);
    };

    const maybeRenderSubmitButton = () => {
        if (promoCodeIsGiftCard) {
            if (isLoading) {
                return <LoadingGif className={styles.loading} />;
            }
            return (
                <Button
                    id="hipay-submit-button"
                    type="submit"
                    form="hipay-form"
                    disabled={!canSubmit}
                    outlined
                    curvy
                    className={cx([styles.submit, styles.button])}
                >
                    {t('subscriptionPayment:SUBMIT_BUTTON2')}
                </Button>
            );
        }

        if (isLoading) {
            return <LoadingGif className={styles.loading} />;
        }
        return (
            <Button
                id="hipay-submit-button"
                type="submit"
                form="hipay-form"
                disabled={!canSubmit}
                outlined
                curvy
                className={cx([styles.submit, styles.button])}
            >
                {t('subscriptionPayment:SUBMIT_BUTTON')}
            </Button>
        );
    };

    // Depending on PaymentCode, render payment mean logos.
    const displayPaymentLogos = (paymentCode: PaymentCode) => {
        const mapLogoFromCode = new Map<PaymentCode, ReactElement>([
            [
                PaymentCode.HIPAY,
                <div className={styles['payment-logos']} key="hipay-logos">
                    <Visa />
                    <Mastercard />
                    <Cb />
                    <Bancontact />
                    <AmericanExpress />
                </div>,
            ],
        ]);
        return mapLogoFromCode.get(paymentCode);
    };
    if (displayCGU) {
        return (
            <BiogaranCGU
                displayCGU={setDisplayCGU}
                setDisableValidateButton={setDisableValidateButton}
            />
        );
    }

    const displayPromoCodeError = () => {
        if (promoCodeError) {
            return (
                <span className={styles['promo-code-error']}>
                    {errorT('PROMO_CODE')}
                </span>
            );
        }
    };
    const handleVerifyPromoCode = () => {
        verifyPromoCode(promoCode, selectedOfferId, choiceTrackerId)
            .then((resp: IVerifyPromoCodeResponse) =>
                setBasketPromoCode(resp.promo_code.id, resp.discount),
            )
            .catch(() => setPromoCodeError(true));
    };

    const maybeRenderGoCardLessLink = () => {
        if (
            paymentTypes.find(
                (paymentType) => paymentType.code === PaymentCode.GOCARDLESS,
            ) !== undefined &&
            !promoCodeIsGiftCard
        ) {
            return (
                <Typography
                    className={styles['gocardless-link']}
                    onClick={() => {
                        if (
                            user &&
                            selectedAccountOptions.includes(
                                SubscriptionOptionType.PREMIUMPACK,
                            )
                        ) {
                            putAccountOption({
                                account_options: [
                                    SubscriptionOptionType.PREMIUMPACK,
                                ],
                                site: user.site,
                            });
                        }
                        setPaymentType(PaymentCode.GOCARDLESS).then(() => {
                            if (isResolvingSubscription) {
                                return onSubmitResolving();
                            }
                            return onSubmit();
                        });
                    }}
                >
                    {t('HipayPayments:GOCARDLESS_LINK')}
                </Typography>
            );
        }
        return null;
    };
    const maybeRenderHipayForm = () => {
        const history = useNavigate();
        if (theme !== Theme.BIOGARAN) {
            return (
                <HipayStep
                    isFunnel
                    hipayFormID={hipayFormID}
                    subscriptionId={subscriptionId}
                    trackerId={currenTrackerId}
                    title={t('subscriptionPayment:SUBMIT_BUTTON')}
                    createSubscription={createSubscription}
                    onSubmit={processPayNow}
                    onSuccess={(onSubmitResponse: any) => {
                        // Redirect to external web page.
                        if (onSubmitResponse.redirect_url) {
                            return redirectToPaymentGate(
                                onSubmitResponse.redirect_url,
                            );
                        }
                        return history(
                            trackersRoute +
                                '/' +
                                formatedId +
                                PaymentRedirectionUrls.SUBSCRIPTION_SUCCESS,
                        );
                    }}
                    onFail={(customMessage: string = t('ERRORS.UNKNOWN')) => {
                        // For Weenect trackers, only display an error notification
                        showNotification({
                            content: customMessage,
                            type: 'error',
                        });
                    }}
                />
            );
        }
    };

    /**
     * Don't display promo-code input if customer has an active Gift card
     * in the basket
     */
    const maybeRenderPromoCodeInput = () =>
        promoCodeIsGiftCard ? null : (
            <div className={styles.inputContainer}>
                <span className={styles['promo-text']}>
                    {t('PROMOCODE_TEXT')}
                </span>
                <div className={styles['promo-input-container']}>
                    {displayPromoCodeError()}
                    <div className={styles['promo-div']}>
                        <input
                            onChange={(e) => {
                                setPromoCode(e.target.value);
                                setPromoCodeError(false);
                            }}
                            className={styles['promo-input']}
                            type="text"
                            id="name"
                            name="name"
                            size={10}
                            placeholder={t('PROMO_CODE')}
                        />
                        <button
                            type="button"
                            disabled={!selectedOfferId || !promoCode}
                            onClick={() => handleVerifyPromoCode()}
                            className={styles['promo-button']}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </div>
        );

    const maybeRenderGiftCardExplanation = () => {
        if (promoCodeIsGiftCard && userChoices.selectedOptions.length === 0) {
            return (
                <React.Fragment>
                    <Headline
                        className={cx(
                            styles['main-headline'],
                            styles.giftCardExplanation,
                        )}
                        center
                    >
                        {subT('PAYMENT_TITLE2')}
                    </Headline>
                    <span className={styles.explanations}>
                        {t('subscriptionProcess:GIFT_CARD_EXPLANATION')}
                        <br />
                        {t('subscriptionProcess:GIFT_CARD_EXPLANATION2')}
                        <br />
                        {subscriptionT('EXPLANATION3')}
                        <br />
                    </span>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <Headline className={styles['main-headline']} center>
                    {subT('PAYMENT_TITLE')}
                </Headline>
                <Headline level={3} className={styles['main-subtitle']} center>
                    {subT('PAYMENT_SUBHEADLINE')} <br />
                    <span style={{ color: 'black' }}>
                        {t('subscriptionSelection:REFUNDED_TEXT')}
                    </span>
                </Headline>
            </React.Fragment>
        );
    };

    const maybeRenderGiftSummaryExplanation = () => {
        if (promoCodeIsGiftCard) {
            return (
                <div className={styles.width33}>
                    <div className={styles.summary}>
                        <Headline level={3}>{subT('SUMMARY')}</Headline>
                        <ConnectedSummaryTable
                            onDeleteOption={onCheckboxChanged}
                        />
                        <Grid
                            className={cx([
                                styles.buttons,
                                styles.submitButtonsMobile,
                            ])}
                        >
                            <BackButton onClick={onGoBack} />
                            {maybeRenderSubmitButton()}
                        </Grid>
                        {renderConfirmationModal
                            ? renderConfirmationModal()
                            : null}
                    </div>
                    <div className={styles.infoTextGrey}>
                        {subT('PAYMENT_INFO')}
                    </div>
                </div>
            );
        }
        return (
            <div className={styles.width33}>
                <div className={styles.summary}>
                    <Headline level={3}>{subT('SUMMARY')}</Headline>
                    <ConnectedSummaryTable onDeleteOption={onCheckboxChanged} />
                    <Grid
                        className={cx([
                            styles.buttons,
                            styles.submitButtonsMobile,
                        ])}
                    >
                        <BackButton
                            onClick={onGoBack}
                            className={styles.button}
                        />
                        {maybeRenderSubmitButton()}
                    </Grid>
                    {renderConfirmationModal ? renderConfirmationModal() : null}
                </div>
            </div>
        );
    };

    return (
        <PageContainer className={styles.container}>
            <div className={styles.flex}>
                <div className={styles.width55}>
                    {maybeRenderGiftCardExplanation()}
                    <div className={styles.grid}>
                        <div className={styles.flexColumn}>
                            <div className={styles.flexColumnReverse}>
                                <CheckSection
                                    key={hipayPaymentType!.code}
                                    checked={
                                        hipayPaymentType!.code ===
                                        selectedPaymentType
                                    }
                                    onChange={() => {
                                        setPaymentType(hipayPaymentType!.code);
                                    }}
                                    className={styles['payment-option']}
                                >
                                    <div className={styles.paymentSelectBlock}>
                                        <div>
                                            <div
                                                className={styles.payementTitle}
                                            >
                                                {hipayPaymentType!.name}
                                            </div>
                                            <div>
                                                {hipayPaymentType!.accepted}
                                            </div>
                                        </div>
                                        <div>
                                            {displayPaymentLogos(
                                                hipayPaymentType!.code,
                                            )}
                                        </div>
                                    </div>
                                </CheckSection>
                            </div>
                            {maybeRenderHipayForm()}
                            {maybeRenderGoCardLessLink()}
                            {maybeRenderPromoCodeInput()}
                        </div>
                    </div>
                </div>
                {maybeRenderGiftSummaryExplanation()}
            </div>
            <Grid className={cx([styles.buttons, styles.submitButtonsDesktop])}>
                <BackButton className={styles.button} onClick={onGoBack} />
                {maybeRenderSubmitButton()}
            </Grid>
            {renderConfirmationModal ? renderConfirmationModal() : null}
        </PageContainer>
    );
};

const makeMapState = (state: IRootState): IState => {
    const getRegistrationDetails = makeGetTrackerRegistrationDetails();
    const getSubscriptionOffersVariants = makeGetSubscriptionsOffersVariants();
    const getAvailablePayments = makeGetAvailablePaymentTypes();
    const userChoices = makeGetSubscriptionProcessChoices();
    const currentUserChoices = userChoices(state);
    const accountOptionTemplate = makeGetAccountOptionsOffers();

    let currentResolvingSub = null;
    let getSubscriptionDetails;
    if (!state.userTrackers.activeTracker) {
        getSubscriptionDetails = makeGetSubscriptionDetailsFromTracker(
            state.userTrackers.activeTracker!,
        );
    } else {
        getSubscriptionDetails = makeGetSubscriptionDetailsFromTracker(
            state.userTrackers.activeTracker!,
        );
    }
    const getRawSubscriptionDetails = makeGetSubscriptionDetailsFromTracker(
        state.userTrackers.activeTracker!,
        false,
    );

    const subscriptionDetails = getSubscriptionDetails(
        state,
    ) as ISubscriptionDetails;

    if (state.userTrackers.activeTracker) {
        currentResolvingSub = makeGetSubscriptionDetailsFromTracker(
            state.userTrackers.activeTracker,
            true,
        )(state) as ISubscriptionDetails;
    }
    if (!subscriptionDetails) {
        return {
            user: state.user.userData,
            accountOptionOffers: accountOptionTemplate(state),
            selectedAccountOptions:
                state.subscriptionProcess.choices.selectedAccountOptions,
            currenTrackerId: currentUserChoices.trackerId,
            canSubmit: state.hipayStep.canSubmit,
            isLoading: state.hipayStep.isLoading,
            offers: getSubscriptionOffersVariants(state),
            choiceTrackerId: userChoices(state).trackerId!,
            isResolvingSubscription:
                state.subscriptionProcess.isResolvingSubscription,
            paymentTypes: getAvailablePayments(state),
            selectedPaymentType: userChoices(state).selectedPaymentCode,
            selectedOfferCode: userChoices(state).subscriptionCode,
            selectedOfferId: userChoices(state).subscriptionOfferId,
            promoCodeIsGiftCard: userChoices(state).promoCodeIsGiftCard,
            subscriptionResolving: currentResolvingSub,
            options: null,
            registrationDetails: getRegistrationDetails(state),
            subscriptionDetails: null,
            rawSubscriptionDetails: null,
            getSubscriptionWhileResolving:
                state.subscriptionProcess.getSubscriptionWhileResolving,
            rawSubscription: getRawSubscriptionDetails(
                state,
            ) as IRawUserSubscription,
        };
    }
    const getSubOptions = makeGetSubscriptionOptions(subscriptionDetails.id);
    return {
        selectedAccountOptions:
            state.subscriptionProcess.choices.selectedAccountOptions,
        user: state.user.userData,
        accountOptionOffers: accountOptionTemplate(state),
        currenTrackerId: currentUserChoices.trackerId,
        canSubmit: state.hipayStep.canSubmit,
        isLoading: state.hipayStep.isLoading,
        options: getSubOptions(state),
        getSubscriptionWhileResolving:
            state.subscriptionProcess.getSubscriptionWhileResolving,
        registrationDetails: getRegistrationDetails(state),
        subscriptionDetails,
        rawSubscriptionDetails: getRawSubscriptionDetails(
            state,
        ) as IRawUserSubscription,
        rawSubscription: getRawSubscriptionDetails(
            state,
        ) as IRawUserSubscription,
        offers: getSubscriptionOffersVariants(state),
        choiceTrackerId: userChoices(state).trackerId!,
        isResolvingSubscription:
            state.subscriptionProcess.isResolvingSubscription,
        paymentTypes: getAvailablePayments(state),
        selectedPaymentType: userChoices(state).selectedPaymentCode,
        selectedOfferCode: userChoices(state).subscriptionCode,
        selectedOfferId: userChoices(state).subscriptionOfferId,
        promoCodeIsGiftCard: userChoices(state).promoCodeIsGiftCard,
        subscriptionResolving: currentResolvingSub,
    };
};

const mapDispatch = (dispatch: Dispatch): IActions => ({
    processPayNow: async (payload) =>
        dispatch.subscriptionProcess.processPayNow(payload),
    showNotification: dispatch.notifications?.showNotification,
    fetchSubscriptionDetails: async (subscriptionId: number) =>
        dispatch.userSubscriptions.fetchSubscriptionDetails(subscriptionId),
    prefillSubscriptionChoices: async (sub) =>
        dispatch.subscriptionProcess.setChoicesFromSubscription(sub),
    setIsResolvingSubscription: async (value: boolean) =>
        dispatch.subscriptionProcess.setIsResolvingSubscription(value),
    setSubscriptionWhileResolving: async (value: boolean) =>
        dispatch.subscriptionProcess.setSubscriptionWhileResolving(value),
    setPaymentType: async (paymentType: PaymentCode) =>
        dispatch.subscriptionProcess.setSelectedPayment(paymentType),
    setSelectedTracker: async (trackerId: number) =>
        dispatch.subscriptionProcess.setSelectedTracker(trackerId),
    setSelectedSubscription: async (subCode: SubscriptionVariantCode) =>
        dispatch.subscriptionProcess.setSelectedSubscription(subCode),
    setSelectedSubscriptionOffer: async (subOfferId: number) =>
        dispatch.subscriptionProcess.setSelectedSubscriptionOfferId(subOfferId),
    setSelectedOptions: dispatch.subscriptionProcess.setSelectedOptions,

    // assign new selected payment values to state
    resetSelectedPaymentType: async (
        availablePayments: IPaymentType[],
        userSelectedPaymentCode: PaymentCode | null,
    ) => {
        const matchedIndex = availablePayments.findIndex(
            (payment) => payment.code === userSelectedPaymentCode,
        );
        // Check if the stored selected payment value is displayed
        // If not displayed, reset to the first one displayed
        // https://redmine.weenect.com/issues/6207
        if (matchedIndex === -1) {
            return dispatch.subscriptionProcess.setSelectedPayment(
                availablePayments[0].code,
            );
        }
    },
    verifyPromoCode: async (
        promoCode: string,
        offerId: number,
        trackerId?: number,
    ) =>
        dispatch.subscriptionProcess.verifyPromoCode({
            promoCode,
            subscriptionOfferId: offerId,
            trackerId,
        }),

    setBasketPromoCode: (promoCodeId: number, discount: number) => {
        dispatch.subscriptionProcess.setPromoCode({
            promoCodeId,
            discount,
        });
    },
});

export const ConnectedPaymentsStep = compose<IPaymentsStepProps, any>(
    connect(makeMapState, mapDispatch),
    hasUserChoicesGuard,
    withSelectedTracker,
)(PaymentsStep);
