import moment from 'moment';

import { getGlobalT } from '../../../i18n/setup-translations';
import { getTimePeriodFromMonths } from '../../../utils/get-time-period-from-months';
import { formatAmountWithCurrency } from '../../../utils/normalize-price-amount';
import { formatDate } from '../../../utils/time-formatters';
import { IRawSubscriptionVariantOffer } from '../../subscription-offers/interfaces-api';
import { ISubscriptionDetails } from '../interfaces';
import { IRawUserSubscription } from '../interfaces-api';
import { getFormattedSubscriptionStatus } from './get-formatted-subscription-status';

const resolveSubscriptionName = (timePeriod: number) => {
    const t = getGlobalT();

    switch (timePeriod) {
        case 1:
            return t('subscriptions:PLAN_NAMES.ONE_MONTH');
        case 3:
            return t('subscriptions:PLAN_NAMES.TRIAL_PERIOD');
        case 12:
            return t('subscriptions:PLAN_NAMES.ONE_YEAR');
        case 24:
            return t('subscriptions:PLAN_NAMES.TWO_YEARS');
        case 36:
            return t('subscriptions:PLAN_NAMES.THREE_YEARS');
        default:
            return t('subscriptions:PLAN_NAMES.FALLBACK');
    }
};

const getDaysToExpire = (expireDate: string | null): number | null => {
    if (!expireDate) {
        return null;
    }

    const today = moment(new Date());
    const expireAt = moment(expireDate);

    return expireAt.diff(today, 'days');
};

const getIsExpired = (expireDate: string | null): boolean => {
    if (!expireDate) {
        return false;
    }
    return new Date(Date.parse(expireDate)) < new Date();
};

const getOfferCodeFromOfferId = (
    offerId: number,
    offers: IRawSubscriptionVariantOffer[],
) => {
    const subscriptionOffer = offers.find((offer) => offer.id === offerId);
    if (subscriptionOffer) {
        return subscriptionOffer.code;
    }
    return offers[0].code; // Fallback (code will never reach this point)
};

export const mapSubscriptionToDetails = (
    rawSub: IRawUserSubscription,
    offers: IRawSubscriptionVariantOffer[],
): ISubscriptionDetails => {
    let renewalDate = null;
    let expiryDate = null;
    let expiryRawDate = null;

    if (
        (rawSub.status === 'active' || rawSub.status === 'cancel_scheduled') &&
        rawSub.next_charge_at
    ) {
        renewalDate = formatDate(rawSub.next_charge_at);
    }

    if (rawSub.status === 'canceled' && rawSub.next_charge_at) {
        expiryDate = formatDate(rawSub.next_charge_at);
        expiryRawDate = rawSub.next_charge_at;
    }

    if (rawSub.offer_type === 'promotional') {
        const createdAtDate = new Date(Date.parse(rawSub.created_at));
        const expiry_date = new Date(createdAtDate.setMonth(createdAtDate.getMonth() + rawSub.period));
        expiryDate = formatDate(expiry_date.toString());
        expiryRawDate = expiry_date.toISOString()
    }

    return {
        id: rawSub.id,
        priceFormatted: formatAmountWithCurrency(
            rawSub.amount,
            rawSub.currency,
        ),
        pricePeriod: getTimePeriodFromMonths(rawSub.period),
        status: rawSub.status,
        statusFormatted: getFormattedSubscriptionStatus(rawSub.status),
        name: resolveSubscriptionName(rawSub.period),
        renewalDate,
        expiryDate,
        expiryRawDate,
        createdAt: formatDate(rawSub.created_at),
        willExpireIn: getDaysToExpire(rawSub.next_charge_at),
        isActive: rawSub.status === 'active',
        isCancelled: rawSub.status === 'canceled',
        isCancelScheduled: rawSub.status === 'cancel_scheduled',
        isExpired: getIsExpired(
            rawSub.offer_type === 'promotional' ?
            rawSub.canceled_at : rawSub.next_charge_at
        ),
        isRefunded: rawSub.status === 'refunded',
        isNew: rawSub.status === 'new',
        payment_mean: rawSub.payment_mean,
        offer_code: getOfferCodeFromOfferId(rawSub.offer_id, offers),
        isUnderCommitmentPeriod: rawSub.is_under_commitment_period,
        isUnderNoticePeriod: rawSub.is_under_notice_period,
        waitingForPayment: rawSub.waiting_for_payment,
        offerType: rawSub.offer_type,
    };
};
