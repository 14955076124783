import { makeGetIsTrackerAnimalType } from 'app/trackers/selectors/get-is-tracker-animal-type';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactGA from "react-ga4";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { compose } from 'recompose';

import { getGlobalT } from '../../../../i18n/setup-translations';
import { DATE_FORMAT } from '../../../../utils/time-formatters';
import { Modal } from '../../../common/modal/modal.component';
import { Typography } from '../../../common/typography/typography.component';
import { changePaymentMeanRoute } from '../../../routing/routes';
import { IRootState, store } from '../../../store/store';
import { CreditCardAlertModal } from '../../../user-subscriptions/credit-card-alert-modal/credit-card-alert-modal.component';
import { SubscriptionOptionType } from '../../../user-subscriptions/interfaces-api';
import {
    makeGetSubscriptionDetails,
    makeGetSubscriptionOptions,
} from '../../../user-subscriptions/selectors/user-subscriptions.selectors';
import { ConnectedSubscriptionAlertModal } from '../../../user-subscriptions/subscription-alert-modal/subscription-alert-modal.component';
import { ConnectedSubscriptionAlertEndTrialModal } from '../../../user-subscriptions/subscription-alert-end-trial-modal/subscription-alert-end-trial-modal.component';

import { ConnectedSubscriptionDetailsManageButton } from '../../../user-subscriptions/subscription-details/manage-button/manage-button.component';
import {
    getCardExpiresSoon,
    getSubscriptionAlertPassedDays,
    getSubscriptionTrialEndAlertTimestamp,
    saveSubscriptionAlertTimestamp,
    saveSubscriptionTrialEndAlertTimestamp
} from '../../../user-subscriptions/subscription.service';
import { makeGetUserWifiZonesForTracker } from '../../../wifi-zones/selectors/wifi-zones.selectors';
import { IAccuracyData, ITrackerOnSidebar } from '../../interfaces';
import { formatAccuracyInfo } from '../../mappers/format-accuracy-info';
import { fetchTracker } from '../../trackers.service';
import { ExpirationInfo } from '../expiration-info/expiration-info.component';
import { TrackerAttributesList } from '../tracker-attributes-list/tracker-attributes-list.component';
import { TrackerCommunicationData } from '../tracker-comms-data/tracker-comms-data.component';
import { TrackerDetails } from '../tracker-details/tracker-details.component';
import { TrackersLinksMenuWithRouter } from '../trackers-links-menu/trackers-links-menu.component';
import styles from './tracker-content.module.scss';

interface IStateProps {
    expiryDate: string | null;
    hasSmsOption: boolean;
    isAnimalType: boolean;
    hasNoWifiZones: boolean;
    currentTrackerId: number;
    subscriptionOfferType: string | null;
}
interface ITrackerContentProps {
    tracker: ITrackerOnSidebar;
}
interface IProps extends IStateProps, ITrackerContentProps { }

export const TrackerContent = ({
    hasNoWifiZones,
    tracker,
    expiryDate,
    isAnimalType,
    hasSmsOption,
    currentTrackerId,
    subscriptionOfferType,
}: IProps) => {
    const t = getGlobalT();
    const {
        accuracy,
        address,
        batteryLevel,
        callTimeRemaining,
        date,
        gps,
        hasSubscription,
        signalStrength,
        subscriptionId,
        subscriptionStatus,
        willExpireIn,
    } = tracker;
    const shouldDisplaySubscriptionAlert = (
        subscriptionId &&
        // Display Alert every 7 days
        ((getSubscriptionAlertPassedDays(subscriptionId) || 7) >= 7 ||
            // But in last week before expiration,
            // display Alert every days
            (willExpireIn <= 7 && (getSubscriptionAlertPassedDays(subscriptionId) || 1) >= 1)));

    const trialAlertTimestamp = getSubscriptionTrialEndAlertTimestamp(subscriptionId || 1);
    const shouldDisplaySubscriptionTrialEndAlert = subscriptionId &&
        (trialAlertTimestamp === null ||
        Date.now() > trialAlertTimestamp) &&
        willExpireIn <= 0;
        
    const [creditCardExpireSoon, setCreditCardExpireSoon] = useState<boolean>(
        false,
    );
    // Fetch from API if subscription is linked to a soon expired credit-card.
    useEffect(() => {
        if (subscriptionId) {
            getCardExpiresSoon(subscriptionId).then((resp) =>
                setCreditCardExpireSoon(resp),
            );
        }
    }, [creditCardExpireSoon, setCreditCardExpireSoon, subscriptionId]);

    const willExpireSoon =
        0 <= willExpireIn &&
        willExpireIn <= 30 &&
        // subscription.next_charge_at needs to be in the future
        moment(expiryDate!, DATE_FORMAT).isAfter(moment()) &&
        hasSubscription &&
        subscriptionStatus === 'canceled';

    const [subscriptionAlertOpen, setSubscriptionAlertOpen] = useState<boolean>(
        subscriptionOfferType !== 'promotional' && willExpireSoon && Boolean(shouldDisplaySubscriptionAlert),
    );
    const [subscriptionEndTrialAlertOpen, setSubscriptionEndTrialAlertOpen] = useState<boolean>(
        Boolean(subscriptionOfferType === 'promotional' && shouldDisplaySubscriptionTrialEndAlert)
    );
    const currentUser = store.getState().user.userData;
    const history = useNavigate()
    // Redirect to changePaymentMean url on creditCardAlertModal confirmation
    const onCreditCardAlertConfirm = () => {
        history(
            changePaymentMeanRoute.replace(':id', tracker.id.toString()),
        );
    };

    const maybeRenderExpireInfo = () => {
        if (willExpireSoon) {
            return (
                <React.Fragment>
                    <ExpirationInfo
                        expireInDays={willExpireIn}
                        trackerName={tracker.name}
                    />
                    <div className={styles['buttons-container']}>
                        <ConnectedSubscriptionDetailsManageButton />
                    </div>
                </React.Fragment>
            );
        }
    };

    const maybeRenderSubscriptionAlertModal = () => {
        if (subscriptionAlertOpen && subscriptionId) {
            return (
                <Modal>
                    <ConnectedSubscriptionAlertModal
                        onConfirm={() => {
                            GoogleAnalyticsOnConfirm(subscriptionId);
                            return fetchTracker(tracker.id).then(() => {
                                saveSubscriptionAlertTimestamp(subscriptionId);
                                setSubscriptionAlertOpen(false);
                            });
                        }}
                        onAbort={() => {
                            GoogleAnalyticsOnAbort(subscriptionId);
                            saveSubscriptionAlertTimestamp(subscriptionId);
                            setSubscriptionAlertOpen(false);
                        }}
                        trackerName={tracker.name}
                        subscriptionId={subscriptionId}
                    />
                </Modal>
            );
        }
    };

    const maybeRenderSubscriptionEndTrialAlertModal = () => {
        if (subscriptionEndTrialAlertOpen && subscriptionId) {
            return (
                <Modal>
                    <ConnectedSubscriptionAlertEndTrialModal
                        onConfirm={() => {
                            return fetchTracker(tracker.id).then(() => {
                                saveSubscriptionTrialEndAlertTimestamp(subscriptionId);
                                setSubscriptionEndTrialAlertOpen(false);
                            });
                        }}
                        onAbort={() => {
                            saveSubscriptionTrialEndAlertTimestamp(subscriptionId);
                            setSubscriptionEndTrialAlertOpen(false);
                        }}
                        trackerName={tracker.name}
                        subscriptionId={subscriptionId}
                    />
                </Modal>
            );
        }
    };

    const maybeRenderCreditCardAlertModal = () => {
        if (
            shouldDisplaySubscriptionAlert &&
            creditCardExpireSoon &&
            subscriptionId
        ) {
            return (
                <Modal>
                    <CreditCardAlertModal
                        onConfirm={() => {
                            saveSubscriptionAlertTimestamp(subscriptionId);
                            setCreditCardExpireSoon(false);
                            onCreditCardAlertConfirm();
                        }}
                        onAbort={() => {
                            saveSubscriptionAlertTimestamp(subscriptionId);
                            setCreditCardExpireSoon(false);
                        }}
                        subscriptionId={subscriptionId}
                    />
                </Modal>
            );
        }
    };

    const GoogleAnalyticsOnConfirm = (subscriptionIdGA: number) => {
        const lastPopupInterval = getSubscriptionAlertPassedDays(
            subscriptionIdGA,
        );

        /** If not null, popup has already been shown more than x days ago
         * (depending on shouldDisplaySubscriptionAlert)
         */
        if (lastPopupInterval) {
            return ReactGA.event({
                action: 'automatic-renewal',
                category: 'popup-automatic-renewal',
                label: 'popup-automatic-renewal_automatic-renewal_popup2',
            });
        }
        return ReactGA.event({
            action: 'automatic-renewal',
            category: 'popup-automatic-renewal',
            label: 'popup-automatic-renewal_automatic-renewal_popup1',
        });
    };

    const GoogleAnalyticsOnAbort = (subscriptionIdGA: number) => {
        const lastPopupInterval = getSubscriptionAlertPassedDays(
            subscriptionIdGA,
        );

        /** If not null, popup has already been shown more than x days ago
         * (depending on shouldDisplaySubscriptionAlert)
         */
        if (lastPopupInterval) {
            return ReactGA.event({
                action: 'don’t-automatic-renewal',
                category: 'popup-automatic-renewal',
                label: 'popup-automatic-renewal_don’t-automatic-renewal_popup2',
            });
        }
        return ReactGA.event({
            action: 'don’t-automatic-renewal',
            category: 'popup-automatic-renewal',
            label: 'popup-automatic-renewal_don’t-automatic-renewal_popup1',
        });
    };

    const maybeRenderTrackerAttributes = () => {
        if (tracker.online) {
            return (
                <TrackerAttributesList
                    gpsSignal={signalStrength}
                    gpsOn={gps}
                    batteryLevel={batteryLevel}
                    accuracyData={accuracyData}
                    className={styles['attributes-list']}
                />
            );
        }

        return <Typography>{t('trackerLeftPanel:OFFLINE')}</Typography>;
    };

    const maybeRenderTrackerCommunicationData = () => {
        if (currentUser) {
            return (
                <TrackerCommunicationData
                    callTimeRemaining={callTimeRemaining}
                    className={styles['tracker-comms']}
                    currentUser={currentUser}
                    hasSmsActivated={hasSmsOption}
                    hasSos={tracker.hasSos}
                />
            );
        }
    };

    let accuracyData: IAccuracyData | null = formatAccuracyInfo(accuracy || 0);
    if (!gps) {
        accuracyData = null;
    }

    return (
        <React.Fragment>
            {maybeRenderTrackerAttributes()}

            {maybeRenderExpireInfo()}

            {maybeRenderTrackerCommunicationData()}

            <TrackerDetails
                deepSleep={tracker.isInDeepSleep}
                isInWifiZone={tracker.isInWifiZone}
                lastPositionAddress={address}
                lastSeenTime={date}
                className={styles['tracker-details']}
                accuracyData={accuracyData}
            />
            <TrackersLinksMenuWithRouter
                isAnimalType={isAnimalType}
                hasNoWifiZones={hasNoWifiZones}
                trackerId={currentTrackerId}
            />

            {maybeRenderSubscriptionAlertModal()}
            {maybeRenderSubscriptionEndTrialAlertModal()}
            {maybeRenderCreditCardAlertModal()}
        </React.Fragment>
    );
};

const makeMapState = () => {
    return (state: IRootState, props: IProps): IStateProps => {
        const getIsAnimalType = makeGetIsTrackerAnimalType(props.tracker.id);
        const getWifiZonesDetails = makeGetUserWifiZonesForTracker(
            props.tracker.id,
        );
        const hasNoWifiZones = getWifiZonesDetails(state).length === 0;
        const fallbackState = {
            isAnimalType: getIsAnimalType(state),
            expiryDate: null,
            hasSmsOption: false,
            hasNoWifiZones,
            currentTrackerId: props.tracker.id,
            subscriptionOfferType: props.tracker.subscriptionOfferType
        };
        if (!props.tracker.subscriptionId) {
            return fallbackState;
        }
        const getSubDetails = makeGetSubscriptionDetails(
            props.tracker.subscriptionId,
        );
        const subscriptionDetail = getSubDetails(state);

        const getOptionsDetails = makeGetSubscriptionOptions(
            props.tracker.subscriptionId,
        );
        const optionsDetails = getOptionsDetails(state);
        let hasSmsActivated = false;
        if (optionsDetails) {
            hasSmsActivated =
                optionsDetails.findIndex(
                    (option) =>
                        option.code === SubscriptionOptionType.SMS &&
                        option.activated === true,
                ) !== -1;
        }
        if (subscriptionDetail) {
            return {
                isAnimalType: getIsAnimalType(state),
                hasNoWifiZones,
                expiryDate: subscriptionDetail.expiryDate,
                hasSmsOption: hasSmsActivated,
                currentTrackerId: props.tracker.id,
                subscriptionOfferType: props.tracker.subscriptionOfferType
            };
        }

        return fallbackState;
    };
};

export const ConnectedTrackerContent = compose<IProps, ITrackerContentProps>(
    connect(makeMapState),
)(TrackerContent);
