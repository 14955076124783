import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { IPaymentHistoryItem } from '../../interfaces';
import { SinglePayment } from '../single-pament/single-payment.component';
import styles from './payments-list.module.scss';

interface IPaymentsListProps extends HTMLAttributes<HTMLUListElement> {
    payments: IPaymentHistoryItem[];
    onInvoiceRequested(paymentId: number): unknown;
}

export const PaymentsList = ({
    className,
    payments,
    onInvoiceRequested,
}: IPaymentsListProps) => {
    const { t } = useTranslation(['payment']);
    const maybeRenderNoPayment = () => {
        if (payments.length === 0) {
            return (<p className={styles.paymentmeanwarning}>{t('NO_PAYMENT_YET')}</p>)
        }
    }

    return (
        <>
            {maybeRenderNoPayment()}
            <ul className={cx(styles.list, className)}>
                {payments
                    .filter(p => p.status !== 'enrolled')
                    .map((p, i) => (
                        <li className={styles.item} key={p.paymentId + p.id}>
                            <SinglePayment
                                formattedDate={p.date}
                                name={p.paymentName}
                                status={p.status}
                                hasInvoice={Boolean(p.invoiceUrl)}
                                onInvoiceRequested={() => onInvoiceRequested(p.id)}
                            />
                        </li>
                    ))}
            </ul>
        </>
    )
}
