import { fetchTrackers } from 'app/trackers/trackers.service';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { compose, mapProps } from 'recompose';

import { Button } from '../../common/button/button.component';
import { Headline } from '../../common/headline/headline.component';
import { Link } from '../../common/link/link.component';
import { Spinner } from '../../common/loading-spinner/loadingSpinner.component';
import { withLanguageDetecting } from '../../language/language-detecting-resolver';
import { SimpleHeaderWithLanguages } from '../../layout/simple-header/simple-header.component';
import { INotification } from '../../notifications/interfaces';

import {
    defaultRoute,
    forgotPasswordRoute,
    signupRoute,
    trackersRoute,
} from '../../routing/routes';
import { Dispatch, IRootState } from '../../store/store';
import { ControlledLoginForm } from '../login-form/login-form.component';
import { ILoginResponse } from '../login.service';
import styles from './login-page.module.scss';
import { ITracker } from 'app/trackers/interfaces-api';
import { includes } from 'lodash';
import { apiService } from 'app/api/api.service';

interface IStateProps {
    loaded: boolean;
    logged: boolean;
    trackers: ITracker[];
}
interface IOuterProps {}

interface IActions {
    refreshTrackerList: () => any;
    submitLogin: (email: string, password: string) => Promise<ILoginResponse>;
    tokenLogin: (token: string) => Promise<ILoginResponse>;
    mailTokenLogin: (token: string) => Promise<ILoginResponse>;
    showNotification: (notification: INotification) => any;
    clearNotifications: () => any;
    updateLoadedState: (value: boolean) => Promise<ILoginResponse>;
    selectActiveTracker: (id: number | null) => Promise<unknown>;
    selectTheme: (id: number | null) => Promise<unknown>;
}

interface IMappedProps {}

interface ILoginPageProps
    extends IOuterProps,
        IActions,
        IMappedProps,
        IStateProps {}

export const LoginPage = ({
    submitLogin,
    refreshTrackerList,
    updateLoadedState,
    tokenLogin,
    mailTokenLogin,
    showNotification,
    clearNotifications,
    selectTheme,
    logged,
    loaded,
    trackers,
}: ILoginPageProps) => {
    const { t } = useTranslation('login');
    const history = useNavigate();
    useEffect(() => {
        // not sur is still usefull since bioggy not anymore in userspace repo
        if (window.location.href.includes('mailtoken')) {
            updateLoadedState(false);
        }
    });

    useEffect(() => {
        const activeTrackerId = params.get('tracker_id');
        const token = params.get('token');
        const mailToken = params.get('mailtoken');
        const isMobileRedirect = params.get('isMobileRedirect');
        const source = params.get('source');
        let redirect = params.get('redirect');
        if (
            isMobileRedirect === null &&
            localStorage.getItem('mobile-redirect') === JSON.stringify(true)
        ) {
            localStorage.removeItem('mobile-redirect');
        }
        if (isMobileRedirect === 'false') {
            localStorage.setItem('mobile-redirect', JSON.stringify(true));
        }
        updateLoadedState(true);
        if (source == 'app' && redirect !== null)
            redirect += redirect.includes('?') ? "&source=app" : "?source=app";
        if (token && activeTrackerId) {
            onTokenSubmitted(token, redirect, parseInt(activeTrackerId, 10));
        }
        if (token && !activeTrackerId) {
            onTokenSubmitted(token, redirect);
        }

        if (mailToken && !activeTrackerId) {
            onMailTokenSubmitted(mailToken, redirect);
        }
        if (mailToken && activeTrackerId) {
            onMailTokenSubmitted(
                mailToken,
                redirect,
                parseInt(activeTrackerId, 10),
            );
        }
    }, []); // Only called once

    const onFormSubmitted = async (email: string, password: string) => {
        if (localStorage.getItem('mobile-redirect') === JSON.stringify(true)) {
            localStorage.removeItem('mobile-redirect');
        }
        submitLogin(email, password)
            .then((resp) => {
                refreshTrackerList().then(() => {
                    updateLoadedState(true);
                    history(trackersRoute);
                    clearNotifications();
                });
            })
            .catch((err) => {
                clearNotifications();
                showNotification({
                    /**
                     * TODO: Add specific errors based on response codes
                     */
                    type: 'error',
                    content: t('LOGIN_ERRORS.UNKNOWN'),
                });
            });
    };

    const onTokenSubmitted = async (
        accessToken: string,
        redirectUrl: string | null,
        trackerId?: number | null,
    ) => {
        tokenLogin(accessToken)
            .then(() => {
                if (trackerId) {
                    return selectTheme(trackerId).then(() => {
                        history(`/add/subscription/${trackerId}`);
                    });
                }
                if (redirectUrl) {
                    if (!logged) {
                        return;
                    }
                    history(redirectUrl);
                } else {
                    history(defaultRoute);
                }
                clearNotifications();
            })
            .catch(() => {
                clearNotifications();
                showNotification({
                    /**
                     * TODO: Add specific errors based on response codes
                     */
                    type: 'error',
                    content: t('LOGIN_ERRORS.UNKNOWN'),
                });
            });
    };

    const tryCohortRedirection = async (
        trackerId?: number | null,
    ) => {
        const c_resp = await apiService.fetch('/user/cohort/payment');

        if (c_resp.status >= 400){
            return false;
        }
        const cohort = await c_resp.json();

        if (cohort.target != "webview") {
            return false;
        }
        const resp = await apiService.fetch(
            '/auth/external-id',{
                method: 'POST',
                body: JSON.stringify({
                    tracker_id: trackerId,
                    service: "payment",
                }),
            }
        );
        if (resp.status >= 400){
            return false;
        }
        const data = await resp.json();
        const target = ((window.location.hostname === "localhost") ? 
            "subscribe.preprod.weenect.com" : window.location.hostname.replace('my', 'subscribe')
        );
        window.location.href = `https://${target}/?auth=${data.external_id}`;
        return true;
    }

    const onMailTokenSubmitted = async (
        mToken: string,
        redirectUrl: string | null,
        trackerId?: number | null,
    ) => {
        try {
            await mailTokenLogin(mToken);
            
            if (trackerId) {
                // Mobile payment view

                // Try cohort redirection
                if (await tryCohortRedirection(trackerId)) {
                    // Stop processing here, browser will be redirected
                    return
                }
                return refreshTrackerList().then((res: any) => {
                    setTimeout(() => {
                        return history(`/add/subscription/${trackerId}`);
                    }, 200);
                });
            }
            if (redirectUrl) {
                if (!logged) {
                    setTimeout(() => {
                        history(redirectUrl);
                        return;
                    }, 200);
                    return;
                }
                history(redirectUrl);
            } else {
                history(defaultRoute);
            }
            clearNotifications();
        } catch {
            updateLoadedState(true);
            clearNotifications();
            showNotification({
                /**
                 * TODO: Add specific errors based on response codes
                 */
                type: 'error',
                content: t('LOGIN_ERRORS.UNKNOWN'),
            });
        };
    };

    const params = new URL(window.location.href).searchParams;
    const mail = params.get('mail');
    const pass = params.get('pass');
    if (mail && pass) {
        onFormSubmitted(mail, pass);
    }

    const loading = () => {
        /* used to not re-reload the login screen while connecting via url if 'loaded' is at false the connection process is still pending */
        if (!loaded) {
            return (
                <div className={styles.container}>
                    <div className={styles.container}>
                        <Spinner loading={!loaded} />
                    </div>
                </div>
            );
        }
    };
    const loginContainer = () => {
        if (loaded) {
            return (
                <>
                    <SimpleHeaderWithLanguages />
                    <div className={styles.container}>
                        <Headline className={styles['primary-headline']} center>
                            {t('SIGN_IN_HEADLINE')}
                        </Headline>

                        <ControlledLoginForm
                            onFormSubmitted={onFormSubmitted}
                            className={styles.form}
                        />

                        <Link
                            center
                            block
                            onClick={() => history(forgotPasswordRoute)}
                            className={styles['forgot-password']}
                        >
                            {t('FORGOT_PASSWORD')}
                        </Link>

                        <Headline
                            level={2}
                            center
                            className={styles['secondary-headline']}
                        >
                            {t('SIGN_UP_HEADLINE')}
                        </Headline>

                        <Button
                            secondary
                            block
                            onClick={() => history(signupRoute)}
                        >
                            {t('SIGN_UP_BUTTON')}
                        </Button>

                        <Link
                            href={t('TERMS_LINK_URL')}
                            target="_blank"
                            center
                            block
                            className={styles['terms-link']}
                        >
                            {t('TERMS_LINK')}
                        </Link>
                    </div>
                </>
            );
        }
    };

    return (
        <div>
            {loading()}
            {loginContainer()}
        </div>
    );
};

const mapState = () => {
    return (state: IRootState): IStateProps => {
        const isLoaded: boolean = state.login.loaded;
        const trackers = state.userTrackers.rawTrackers;
        return {
            trackers: trackers,
            loaded: isLoaded,
            logged: state.login.logged,
        };
    };
};

const mapDispatch = (dispatch: Dispatch) => ({
    refreshTrackerList: () =>
        dispatch.userTrackers.fetchTrackers({ deepFetch: true }),
    submitLogin: (email: string, password: string) =>
        dispatch.login.requestLogin({
            email,
            password,
        } as any),
    tokenLogin: async (token: string) => dispatch.login.tokenLogin(token),
    mailTokenLogin: async (token: string) =>
        dispatch.login.mailTokenLogin(token),
    showNotification: dispatch.notifications.showNotification,
    updateLoadedState: async (loaded: boolean) =>
        dispatch.login.updateLoading(loaded),
    selectActiveTracker: async (id: number | null) =>
        dispatch.userTrackers.selectActiveTracker(id as any),
    selectTheme: async (id: number | null) =>
        dispatch.userTrackers.selectTheme(id as any),
    clearNotifications: dispatch.notifications.clearNotifications,
});

export const ConnectedLoginPage = compose<ILoginPageProps, IOuterProps>(
    mapProps(
        (props: IOuterProps): IMappedProps => ({
            ...props,
            redirectToforgotPassword() {
                const history = useNavigate();
                history(forgotPasswordRoute);
            },
        }),
    ),
    connect(mapState(), mapDispatch),
    withLanguageDetecting,
)(LoginPage);
