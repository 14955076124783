import { ModelConfig } from '@rematch/core';
import { useNavigate } from 'react-router';

import { config as appConfig } from '../../config';
import { subscriptionProcessRoute } from '../routing/routes';
import { IRootState } from '../store/store';
import { SubscriptionVariantCode } from '../subscription-offers/interfaces';
import { makeGetSubscriptionsOffersVariants } from '../subscription-offers/selectors/subscription-offers-selectors';
import {
    IRawUserSubscription,
    SubscriptionOptionType,
} from '../user-subscriptions/interfaces-api';
import {
    ISubscriptionVariantChoice,
    IVerifyPromoCode,
    PaymentCode,
} from './interfaces';
import { createNewSubscriptionToUserChoices } from './mappers/new-subscription-to-user-choices';
import {
    redirectToPaymentGate,
    requestPayNow,
    requestPromoCodeActivation,
    requestSubscriptionOrder,
} from './services/order-subscription.service';
import { IProcessPayment } from './steps/hipay-step/interfaces';

export interface IUserChoices {
    subscriptionCode: SubscriptionVariantCode;
    subscriptionOfferId: number;
    selectedOptions: SubscriptionOptionType[];
    selectedAccountOptions: SubscriptionOptionType[];
    selectedPaymentCode: PaymentCode;
    trackerId: number | null;
    promoCodeId: number | null;
    promoCodeDiscount: number | null;
    promoCodeIsGiftCard: boolean | null;
}

export interface ISubscriptionProcessStore {
    choices: IUserChoices;
    customerHasGiftCard: boolean;
    isResolvingSubscription: boolean;
    getSubscriptionWhileResolving: boolean;
}

export const getDefaultSubscriptionCode = (): SubscriptionVariantCode =>
    '12_month_1_tracker';
const getDefaultPaymentTypeCode = (): PaymentCode => PaymentCode.HIPAY;

const getDefaultSelectedOptions = (): SubscriptionOptionType[] => [];

const getEmptyChoices = (): IUserChoices => ({
    selectedAccountOptions: getDefaultSelectedOptions(),
    selectedOptions: getDefaultSelectedOptions(),
    subscriptionOfferId: 0,
    selectedPaymentCode: getDefaultPaymentTypeCode(),
    subscriptionCode: getDefaultSubscriptionCode(),
    trackerId: null,
    promoCodeId: null,
    promoCodeDiscount: null,
    promoCodeIsGiftCard: null,
});

export const subscriptionProcessStore: ModelConfig<ISubscriptionProcessStore> = {
    state: {
        choices: getEmptyChoices(),
        customerHasGiftCard: false,
        getSubscriptionWhileResolving: false,
        isResolvingSubscription: false,
    },
    effects: (dispatch) => ({
        async setSelectedTracker(payload: number) {
            this.setUserChoices({ trackerId: payload });
        },
        async setSelectedSubscription(payload: SubscriptionVariantCode) {
            this.setUserChoices({ subscriptionCode: payload });
        },
        async setSelectedSubscriptionOfferId(payload: number) {
            this.setUserChoices({ subscriptionOfferId: payload });
        },
        async set3yearPlan() {
            this.setUserChoices({ subscriptionCode: '36_month_1_tracker' });
        },
        async setSelectedOptions(payload: SubscriptionOptionType[]) {
            this.setUserChoices({ selectedOptions: payload });
        },
        async setSelectedAccountOptions(payload: SubscriptionOptionType[]) {
            this.setUserChoices({ selectedAccountOptions: payload });
        },
        async appendNewOption(payload: string, state) {
            const currentOptions =
                state.subscriptionProcess.choices.selectedOptions;

            currentOptions.push(payload);

            this.setUserChoices({ selectedOptions: currentOptions });
        },
        async removeOption(payload: string, state) {
            const currentOptions: string[] =
                state.subscriptionProcess.choices.selectedOptions;

            const newOptions = currentOptions.filter((opt) => opt !== payload);

            this.setUserChoices({ selectedOptions: newOptions });
        },
        async setSelectedSubscriptionVariant(
            payload: ISubscriptionVariantChoice,
        ) {
            this.setUserChoices({
                subscriptionCode: payload.subscriptionOfferCode,
                subscriptionOfferId: payload.subscriptionOfferId,
            });
        },
        setPromoCode(payload: {
            promoCodeId: number;
            discount: number;
            isGiftCard?: boolean | undefined;
        }) {
            this.setUserChoices({
                promoCodeId: payload.promoCodeId,
                promoCodeDiscount: payload.discount,
            });
            if (payload.isGiftCard !== undefined) {
                this.setUserChoices({
                    promoCodeIsGiftCard: payload.isGiftCard,
                });
            }
        },
        resetPromoCode() {
            this.setUserChoices({
                promoCodeId: null,
                promoCodeDiscount: null,
                promoCodeIsGiftCard: false,
            });
        },
        setCustomerHasGiftCard(hasGiftCard: boolean) {
            this.doSetCustomerHasGiftCard(hasGiftCard);
        },
        setSelectedPayment(payload: PaymentCode) {
            this.setUserChoices({ selectedPaymentCode: payload });
        },
        async submitSubscriptionRequest(payload: any, model: any) {
            const data = model.subscriptionProcess.choices as IUserChoices;
            const currentSite = model.user.userData.site;

            return requestSubscriptionOrder(data, currentSite).then((resp) => {
                // Update tracker model allows to be aware of the newly created subscription
                const promises: Promise<any>[] = [];
                promises.push(
                    //@ts-ignore
                    dispatch.userTrackers.fetchSingleTracker({
                        trackerId: data.trackerId,
                    }) as Promise<any>,
                );

                Promise.all(promises).then(() => {
                    // Don't redirect to payment gate for Hipay
                    // as we use hosted fields directly on our page.
                    if (data.selectedPaymentCode === PaymentCode.HIPAY) {
                        return useNavigate()(
                            subscriptionProcessRoute +
                            `/credit-card/tracker_id=${data.trackerId}`,
                        );
                    }
                    return redirectToPaymentGate(resp.redirect_url);
                });
            });
        },
        async submitSubscriptionRequestHipay(payload: any, model: any) {
            const data = model.subscriptionProcess.choices as IUserChoices;
            const currentSite = model.user.userData.site;
            return requestSubscriptionOrder(data, currentSite)
                .then((resp) => {
                    // Update tracker model allows to be aware of the newly created subscription
                    //@ts-ignore
                    dispatch.userTrackers.fetchSingleTracker({
                        trackerId: data.trackerId,
                    });
                })
                .catch((resp) => {
                    throw resp;
                });
        },
        /**
         * Call the API pay-now endpoint.
         * @param payload
         */
        async processPayNow(payload: IProcessPayment) {
            if (payload.subscriptionId === null)
                throw new Error('Subscription ID is missing.');
            return requestPayNow(payload);
        },
        async setChoicesFromSubscription(
            payload: IRawUserSubscription,
            models,
        ) {
            const getSubscriptionOffers = makeGetSubscriptionsOffersVariants();

            const newSubscriptionToUserChoices = createNewSubscriptionToUserChoices(
                getSubscriptionOffers(models),
            );

            this.setUserChoices(newSubscriptionToUserChoices(payload));
        },
        async verifyPromoCode(payload: IVerifyPromoCode, state: IRootState) {
            return requestPromoCodeActivation(payload, state);
        },
    }),
    reducers: {
        setUserChoices: (
            state: ISubscriptionProcessStore,
            choices: Partial<IUserChoices>,
        ): ISubscriptionProcessStore => ({
            ...state,
            choices: { ...state.choices, ...choices },
        }),
        clearUserChoices: (state: ISubscriptionProcessStore) => ({
            ...state,
            choices: getEmptyChoices(),
        }),
        setIsResolvingSubscription: (
            state: ISubscriptionProcessStore,
            newValue: boolean,
        ): ISubscriptionProcessStore => ({
            ...state,
            isResolvingSubscription: newValue,
        }),
        setSubscriptionWhileResolving: (
            state: ISubscriptionProcessStore,
            newValue: boolean,
        ): ISubscriptionProcessStore => ({
            ...state,
            getSubscriptionWhileResolving: newValue,
        }),
        doSetCustomerHasGiftCard: (
            state: ISubscriptionProcessStore,
            hasGiftCard: boolean,
        ): ISubscriptionProcessStore => ({
            ...state,
            customerHasGiftCard: hasGiftCard,
        }),
    },
};
